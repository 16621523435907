import React from 'react';
import PageTopComponent from '../components/PageTopComponent/PageTopComponent';
import Services2 from '../components/Services2/Services2';



function Services() {


  return (
    <div className='pt-20'>
    <PageTopComponent type='Services'/>
    <Services2/>
 </div>
  );
}

export default Services;
